
import { defineComponent, ref} from "vue";
import OverlayPanel from "primevue/overlaypanel";
import LoginPanel from "./LoginPanel.vue";
import useAuthentication from '../composables/useAuthentication';
export default defineComponent({
  name: "Footer",
  components: { OverlayPanel, LoginPanel },
  setup() {
    const {isAuthenticated} = useAuthentication();
    const op = ref();
    const toggle = (event: any) => {
      op.value.toggle(event);
    };
    return { op, toggle, isAuthenticated };
  },
});
