import axios from "axios";

const axiosBasic = axios.create({
  headers: {
    Accept: "application/json",
  },
});

const axiosBlog = axios.create({
  // baseURL: '/api',
  // baseURL: process.env.VUE_APP_BASE_URL,
  headers: {
    Accept: "application/json",
    "content-type": "application/json", },
});



// const domainEndpoint = function() {
//   // Get the domainid from DomainStore
//   const domainid = domainStore.getState().domainid;
//   if(domainid){
//       return "/domain/" + domainid;
//   }else{
//       console.error("domainid not initiated");
//   }
// }



axiosBlog.interceptors.request.use((request) => {
  // Interceptor to add Bearer token if we have it set in userStore
  // const authToken = userStore.getState().authToken;
  // console.log(request);
  //   if (authToken) {
  // console.log("setting auth-token header to request");
  // request.headers["Authorization"] = "Bearer " + authToken;
  //   }

  // console.log('Starting Request', JSON.stringify(request, null, 2))
  return request;
});

axiosBlog.interceptors.response.use((response) => {
  // Interceptor to add Bearer token if we have it set in userStore
  // const authToken = userStore.getState().authToken;
  // console.log(response);
  //   if (authToken) {
  // console.log("setting auth-token header to request");
  // request.headers["Authorization"] = "Bearer " + authToken;
  //   }

  // console.log('Starting Request', JSON.stringify(request, null, 2))
  return response;
});


export { axiosBasic, axiosBlog };
