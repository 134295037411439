
import { defineComponent, ref, onMounted, watch } from "vue";
import ArticleForm from "../components/ArticleForm.vue";
import { useRoute } from "vue-router";
import Article from "../components/Article.vue";
import Panel from "primevue/panel";
import useAuthentication from "../composables/useAuthentication";
import { articleStore } from "../store/article-store";

export default defineComponent({
  name: "Blog",
  components: {
    ArticleForm,
    Article,
    Panel,
  },
  props: {},
  setup() {
    const route = useRoute();
    const articles = ref();
    const article = ref();
    const { isAuthenticated } = useAuthentication();

    const updateArticle = async () => {
      if (route.params.id) {
        article.value = articleStore.getArticle(Number(route.params.id));
      } else {
        articles.value = await articleStore.getArticles();
      }
    };

    onMounted(async () => {
      if (!articles.value && !route.params.id && !route.params.tag) {
        const response = await articleStore.getArticles();
        if (articles.value !== response) {
          articles.value = response;
        }
      }

      if (route.params.id) {
        articleStore
          .getArticle(Number(route.params.id))
          .then((response: any) => {
            article.value = response;
            
          });
      }
      if (!route.params.id) {
        article.value = null;
        articles.value = articleStore.getState().articles;
      }

      if (route.params.tag) {
        const response = await articleStore.getArticlesByCategory(
          String(route.params.tag)
        );
        if (articles.value !== response) {
          articles.value = response;
        }
        articles.value = response;
      }
    });
    watch(route, async () => {
      if (!route.params.id && !route.params.tag) {
        const response = await articleStore.getArticles();
        articles.value = response;
      }

      if (route.params.id) {
        article.value = await articleStore.getArticle(Number(route.params.id));
      }
      if (!route.params.id) {
        article.value = null;
        articles.value = articleStore.getState().articles;
        articleStore.setArticle(null);
      }

      if (route.params.tag) {
        const response = await articleStore.getArticlesByCategory(
          String(route.params.tag)
        );
        if (articles.value !== response) {
          articles.value = response;
        }
      }
    });
    // watch(store.state.articles, (old, to) => {
    //   console.log(store.state.articles);

    //   console.log("old", old);
    //   console.log("to", to);
    // });
    watch(articles, async (old, to) => {
      // if (old !== to) {
      //   console.log("old", old);
      //   console.log("to", to);
      // }
      if (old !== to && !route.params.id && !route.params.tag) {
        articles.value = articleStore.getState().articles;
      }
      // if (old !== to && route.params.id) {
      //   article.value = await store.dispatch(
      //     "getCurrentArticle",
      //     route.params.id
      //   );
      // }
    });

    return {
      article,
      articles,
      //toggles
      isAuthenticated,
      updateArticle,
    };
  },
});
