
import { ref } from "vue";
import { useStore } from "vuex";
import { axiosBlog } from "../api/axios";
import FileUpload from "primevue/fileupload";
import { useToast } from "primevue/usetoast";

export default {
  name: "Add",
  props: {
    title: { type: String, required: false },
    location: { type: String, required: true, default: "/api/file/gallery/" },
  },
  components: { FileUpload },
  setup(props: any, { emit }: any) {
    const toast = useToast();

    const files = ref();
    const uploadPercentage = ref(0);
    const handleSubmit = (event: any) => {
      files.value = event.files;
      const formData = new FormData();

      files.value.forEach((image: any, index: number) => {
        formData.append("file", image, image.name.replace(/\s+/g, ""));
      });

      axiosBlog
        .post(props.location, formData, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
          onUploadProgress: function (progressEvent: any) {
            uploadPercentage.value = parseInt(
              String(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              )
            );
          }.bind(this),
        })
        .then((response: any) => {
          toast.add({
            severity: "success",
            summary: "Bilder hochgeladen",
            detail: "Die Bilder wurden erfolgreich hochgeladen",
            life: 3000,
          });
          if (props.location === "/api/file/entryImg") {
            let imageUrl = response.data.filename;
            imageUrl = imageUrl.replace(/\s+/g, "");
            emit("uploadFinished", imageUrl);
          }
        }).catch((error: any) => {
          console.log(error);
          
                    toast.add({
            severity: "error",
            summary: "Fehler",
            detail: "Die Bilder konnten nicht hochgeladen werden",
            life: 3000,
          });
        });
    };

    return {
      // uploadFile,
      handleSubmit,
      uploadPercentage,
    };
  },
};
