import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, createElementVNode as _createElementVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-0d9c8043"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "container" }
const _hoisted_2 = { class: "reverseorder" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_CommentForm = _resolveComponent("CommentForm")!
  const _component_Panel = _resolveComponent("Panel")!
  const _component_Comment = _resolveComponent("Comment")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", null, [
      _createVNode(_component_Panel, {
        class: "comment-panel",
        header: "Kommentar hinterlassen",
        toggleable: true,
        collapsed: _ctx.isCollapsed
      }, {
        default: _withCtx(() => [
          _createVNode(_component_CommentForm, {
            article: _ctx.article,
            onCommentPosted: _ctx.onUpdatePosted
          }, null, 8, ["article", "onCommentPosted"])
        ]),
        _: 1
      }, 8, ["collapsed"])
    ]),
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.comments, (comment) => {
        return (_openBlock(), _createElementBlock("div", {
          key: comment.id
        }, [
          _createVNode(_component_Comment, {
            comment: comment,
            onDeleteComment: _ctx.onDeleteComment
          }, null, 8, ["comment", "onDeleteComment"])
        ]))
      }), 128))
    ])
  ]))
}