import { axiosBlog } from "./axios";

const endpoint_user = "/users/";
const endpoint_auth = "/api/auth/";


export default {
    getToken(email: string, password: string) {
        return axiosBlog({
            method: 'POST',
            url: endpoint_auth + 'login',
            data: {email: email, password: password}
        })
            .then(response => {
                if (response && response.data.user) {
                    return response.data;
                } else {
                    console.log("error");
                }
            })
            .catch(error => {
                console.log(error);
            });
    },
    getUserDescription(id: number) {
        return axiosBlog({
            method: 'GET',
            url: endpoint_user + id,
        })
            .then(response => {
                if (response && response.data.success) {
                    return response.data;
                } else {
                    console.log("error");
                }
            })
            .catch(error => {
                console.log(error);
            });
    },

    updateUserDescription(id: number, description: string) {
        return axiosBlog({
            method: 'PUT',
            url: endpoint_user + id,
            data: { description: description }
        })
            .then(response => {
                if (response && response.data.success) {
                    return response.data;
                } else {
                    console.log("error");
                }
            })
            .catch(error => {
                console.log(error);
            });
    },

}