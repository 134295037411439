import { PersistentStore, Store } from "./main";
import { RepositoryFactory } from "@/api/repositoryFactory";
import { LoaderOptionsPlugin } from 'webpack';
import { axiosBlog } from '@/api/axios';
import { ArticleInterface } from '@/interfaces/article.interface';
import { TagInterface } from '@/interfaces/tag.interface';
import { useCookies } from 'vue3-cookies';
import { blogStore } from './blog-store';
import { userStore } from './user-store';
interface ArticleStoreModel extends Object {
    articles: ArticleInterface[] | null | undefined,
    tags: TagInterface[] | null,
    currentArticle: ArticleInterface | null | undefined,
    isLoading: boolean,
}
const articleRepository = RepositoryFactory.get('article');

class ArticleStore extends PersistentStore<ArticleStoreModel> {
    protected data(): ArticleStoreModel {
        return {
            articles: [],
            tags: null,
            currentArticle: null,
            isLoading: false,
        };
    }
    getArticle(id: number) {
        return new Promise((resolve, reject) => {
            articleRepository.getCurrentArticle(id).then((response: ArticleInterface) => {
                this.setArticle(response)
                resolve(response);
            }).catch((error: any) => {
                reject(error)
            })
        })
    }
    async getArticles() {
        blogStore.setIsLoading(true);
        const URL = userStore.getState().isAuthenticated ? '/api/article/' : '/api/article/published/'
        const response = await axiosBlog.get(URL);
        if (response) {
            blogStore.setIsLoading(false);
        }
        this.state.articles = response.data
        //sort by date
        response.data.sort(function (a: any, b: any) {
            return a.date - b.date;
        }).reverse();


        return response.data;
    }

    async getLastArticles(amount: number) {
        let lastArticles;
        if (!this.state.articles || this.state.articles.length === 0) {
            const response = await this.getArticles()

            lastArticles = response.slice(0, amount)
        }
        else { lastArticles = this.state.articles.slice().reverse().slice(0, amount) }
        return lastArticles;
    }
    async getAllTags() {
        const response = await axiosBlog.get('/api/tag/');
        this.state.tags = response.data;
        return response.data;
    }
    async getArticlesByCategory(searchTag: string) {
        const response = await axiosBlog.get('/api/tag/' + searchTag)
        return response.data
    }
    setArticles(articles: ArticleInterface[]) {
        this.state.articles = articles;
    }
    setArticle(article: ArticleInterface | null) {
        this.state.currentArticle = article;
    }
    setTags(tags: TagInterface[]) {
        this.state.tags = tags;
    }
    async createArticle(form: any) {
        axiosBlog
            .post('/api/article/', form)
            .then((response: any) => {
                this.state.articles?.push(response.data);
                return response
            })
            .catch((error: any) => {
                console.log(error);
                return error
            });
    }

    async updateArticle(id: number, form: any) {
        const response = await axiosBlog
            .put('/api/article/' + id, form)
            .then((response: any) => {
                this.removeArticle(id)
                this.state.articles?.push(response.data);
            })
            .catch((error: any) => {
                console.log(error);
            });
        return response;
    }
    async deleteArticle(id: number) {
        const response = await axiosBlog
            .delete('/api/article/' + id)
            .then((response: any) => {

                this.removeArticle(id)
                return response;
            })
            .catch((error: any) => {
                console.log(error);
            });
        return response;
    }
    removeArticle(id: number) {
        const newArray = this.state.articles!.filter((article: any) => {
            return article.id !== id;
        });
        this.state.articles = newArray;
    }
}
export const articleStore = new ArticleStore('ARTICLE_STORE');