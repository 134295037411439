import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withModifiers as _withModifiers, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-8b0068c8"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "comment-form" }
const _hoisted_2 = { class: "row comment-header" }
const _hoisted_3 = { class: "row" }
const _hoisted_4 = { class: "row" }
const _hoisted_5 = { class: "row comment-content" }
const _hoisted_6 = { class: "row capture" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_InputText = _resolveComponent("InputText")!
  const _component_Textarea = _resolveComponent("Textarea")!
  const _component_InputNumber = _resolveComponent("InputNumber")!
  const _component_Button = _resolveComponent("Button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("form", {
      onSubmit: _cache[4] || (_cache[4] = _withModifiers(
//@ts-ignore
(...args) => (_ctx.processCommentForm && _ctx.processCommentForm(...args)), ["prevent"]))
    }, [
      _createElementVNode("div", _hoisted_2, [
        _createElementVNode("div", _hoisted_3, [
          _createVNode(_component_InputText, {
            placeholder: "Name eingeben",
            required: "true",
            modelValue: _ctx.author,
            "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.author) = $event))
          }, null, 8, ["modelValue"])
        ]),
        _createElementVNode("div", _hoisted_4, [
          _createVNode(_component_InputText, {
            modelValue: _ctx.title,
            "onUpdate:modelValue": _cache[1] || (_cache[1] = ($event: any) => ((_ctx.title) = $event)),
            placeholder: "Titel eingeben"
          }, null, 8, ["modelValue"])
        ])
      ]),
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_Textarea, {
          modelValue: _ctx.content,
          "onUpdate:modelValue": _cache[2] || (_cache[2] = ($event: any) => ((_ctx.content) = $event)),
          autoResize: true,
          required: "true",
          rows: "5",
          placeholder: "Kommentar eingeben ..."
        }, null, 8, ["modelValue"])
      ]),
      _createElementVNode("div", _hoisted_6, [
        _createTextVNode(_toDisplayString(_ctx.a) + " + " + _toDisplayString(_ctx.b) + " = ", 1),
        _createVNode(_component_InputNumber, {
          modelValue: _ctx.userSolution,
          "onUpdate:modelValue": _cache[3] || (_cache[3] = ($event: any) => ((_ctx.userSolution) = $event)),
          placeholder: "Lösung eingeben"
        }, null, 8, ["modelValue"])
      ]),
      _createVNode(_component_Button, {
        icon: "pi pi-check",
        type: "submit",
        label: "Submit",
        borderless: ""
      })
    ], 32)
  ]))
}