
import { defineComponent, computed, watch, ref, nextTick } from "vue";
import { useConfirm } from "primevue/useconfirm";
import ConfirmPopup from "primevue/confirmpopup";
import CommentList from "./CommentList.vue";
import { useRoute } from "vue-router";
import {userStore} from '../store/user-store'
import {articleStore} from '../store/article-store'

export default defineComponent({
  props: {
    article: { type: Object, required: true },
    preview: { type: Boolean, default: false },
  },
  components: { ConfirmPopup, CommentList },
  setup(props, { emit }) {
    const route = useRoute();
    const commentsRef = ref();
    const entryImgLocation = "/api/file/entryImg/";
    const isAuthenticated = computed(() => {
      return userStore.getState().isAuthenticated
    });
    const deleteArticle = () => {
      articleStore.deleteArticle(props.article.id)
      
      emit("onUpdateArticle");
    };

    const confirm = useConfirm();
    const confirmDeletion = (event: any) => {
      confirm.require({
        target: event.currentTarget,
        message: "Möchtest du den Artikel wirklich löschen?",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          deleteArticle();
        },
        reject: () => {
          //do nothing
        },
      });
    };

    const scrollIntoView = (id: any) => {
      nextTick(() => {
        commentsRef.value.scrollIntoView(true, { behavior: "smooth" });
      });
    };

    watch(
      () => route.query,
      () => {
        if (route.query.location === "comments") {
          nextTick(() => {
            scrollIntoView("comments");
          });
        }
      },
      { deep: true }
    );
    return { confirmDeletion, isAuthenticated, entryImgLocation, commentsRef };
  },
});
