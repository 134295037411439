
import Toast from 'primevue/toast';

import Header from "./components/Header.vue";
import Navigation from "./components/Navigation.vue";
import Footer from "./components/Footer.vue";
import { computed, defineComponent, onMounted, watch } from "vue";
import { useCookies } from "vue3-cookies";
import Widget from "./components/Widget.vue";
import { useRoute } from "vue-router";
import { blogStore } from './store/blog-store';
import { userStore } from './store/user-store';
import { articleStore } from './store/article-store';
export default defineComponent({
  name: "App",
  components: { Header, Footer, Navigation, Widget, Toast },
  setup() {
    const route = useRoute();
    const { cookies } = useCookies();
    const token = cookies.get("token");
    const isLoading = computed(() => {
      return blogStore.getState().isLoading;
    })
    if (token) {
      const user = userStore.getState().user;
      userStore.login(token, user);
    }

    onMounted(() => {
      articleStore.getArticles()
    });

    watch(route, (to, from) => {
      if (typeof to.meta.title === "string") {
        document.title = 'KoboldBlog |  ' + to.meta.title || "KoboldBlog";
      }
    });
    return {isLoading};
  },
});
