import { axiosBlog } from "./axios";

const endpoint_article = "/article/";
const endpoint_tags = "/tags/";
const endpoint_comments = "/comments/";


export default {
    getArticles() {
        return axiosBlog({
            method: 'GET',
            url: endpoint_article,
        })
            .then(response => {
                if (response && response.data.success) {
                    return response.data;
                } else {
                    console.log("error");
                }
            })
            .catch(error => {
                console.log(error);
            });
    },

    getPublishedArticles() {
        return axiosBlog({
            method: 'GET',
            url: endpoint_article + 'published/',
        })
            .then(response => {
                if (response && response.data.success) {
                    return response.data;
                } else {
                    console.log("error");
                }
            })
            .catch(error => {
                console.log(error);
            });
    },
    getCurrentArticle(id: number) {
        return axiosBlog({
            method: 'GET',
            url: '/api' + endpoint_article + id,
        })
            .then(response => {
                if (response && response.data) {
                    return response.data;
                } else {
                    console.log(response);
                }
            })
            .catch(error => {
                console.log(error);
            });
    },

    getArticlesByCategory(searchTag: string) {
        return axiosBlog({
            method: 'GET',
            url: endpoint_tags + searchTag,
        })
            .then(response => {
                if (response && response.data.success) {
                    return response.data;
                } else {
                    console.log("error");
                }
            })
            .catch(error => {
                console.log(error);
            });
    },

    getAllTags() {
        return axiosBlog({
            method: 'GET',
            url: endpoint_tags,
        })
            .then(response => {
                if (response && response.data.success) {
                    return response.data;
                } else {
                    console.log("error");
                }
            })
            .catch(error => {
                console.log(error);
            });
    },

    getArticleTags(id: number) {
        return axiosBlog({
            method: 'GET',
            url: endpoint_article + 'tags/' + id,
        })
            .then(response => {
                if (response && response.data.success) {
                    return response.data;
                } else {
                    console.log("error");
                }
            })
            .catch(error => {
                console.log(error);
            });
    },

    // createArticle(form: any) {
    //     return axiosBlog.post(endpoint_article, form).then((response: any) => {
    //         if (response && response.data.success) {
    //             return response.data;
    //         } else console.log('error');

    //     }).catch((error => {
    //         console.log(error);
    //     })
    // },
    createArticle(form: any) {
        return axiosBlog({
            method: 'POST',
            url: endpoint_article,
            data: form
        })
            .then(response => {
                if (response && response.data.success) {
                    return response.data.data;
                } else {
                    console.log("error");
                }
            })
            .catch(error => {
                console.log(error);
            });
    },

    updateArticle(id: number, form: any) {
        return axiosBlog({
            method: 'PUT',
            url: endpoint_article + id,
            data: form
        })
            .then(response => {
                if (response && response.data.success) {
                    return response.data.data;
                } else {
                    console.log("error");
                }
            })
            .catch(error => {
                console.log(error);
            });
    },
    deleteArticle(id: number) {
        return axiosBlog({
            method: 'DELETE',
            url: endpoint_article + id,
        })
            .then(response => {
                if (response && response.data.success) {
                    return response.data.data;
                } else {
                    console.log("error");
                }
            })
            .catch(error => {
                console.log(error);
            });
    },

    fileUpload(formData: FormData) {
        return axiosBlog({
            method: 'POST', url: '/file',
            data: formData,
            headers: {
                'Content-Type': 'multipart/form-data',
            },
        }).then((response: any) => {
            if (response && response.data.success) {
                return response.data.data;
            } else {
                console.log("error");
            }
        })
            .catch(error => {
                console.log(error);
            });
    },
    deleteComment(id: number) {
        return axiosBlog({
            method: 'DELETE',
            url: endpoint_comments + id,
        })
            .then(response => {
                if (response && response.data.success) {
                    return response.data;
                } else {
                    console.log("error");
                }
            })
            .catch(error => {
                console.log(error);
            });
    },
}