import UserRepository from "./userRepository";
import ArticleRepository from "./articleRepository";

const repositories:any = {
    user: UserRepository,
    article: ArticleRepository,
};

export const RepositoryFactory = {
    get: (name: string) => repositories[name]
}