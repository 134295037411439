import { PersistentStore } from "./main";
import { RepositoryFactory } from "@/api/repositoryFactory";
import { axiosBlog } from '@/api/axios';
import { useCookies } from 'vue3-cookies';
interface UserStoreModel extends Object {
    user: any | null,
    token: any | null,
    isAuthenticated: boolean,
    userDescription: string | null,
}
const userRepository = RepositoryFactory.get('user');
const { cookies } = useCookies();

class UserStore extends PersistentStore<UserStoreModel> {
    protected data(): UserStoreModel {
        return {
            user: null,
            token: null,
            isAuthenticated: false,
            userDescription: null
        };
    }
    async getUserDescription(id: number) {
        // if (state.userDescription.length > 0) {
        //   return state.userDescription;
        // }
        const response = await axiosBlog
            .get('/api/users/' + id)
            .then((response: any) => {
                this.setUserDescription(response.data);
                return response.data;
            })
            .catch((error: any) => {
                console.log(error);
            });
        return response;
    }
    setUserDescription(description: string) {
        this.state.userDescription = description
    }
    async updateUserDescription(id: number, description: string) {
        const response = await axiosBlog
            .put('/api/users/' + id, { description: description })

            .catch((error: any) => {
                console.log(error);
            });
        return response;
    }
    async getToken(email: string, password: string) {
        const response = await userRepository.getToken(email, password).then((response: any) => {
            const token = response.access_token;
            const user = response.user;
            this.login(token, user);
        })
        return response
    }
    setToken(token: string) {
        cookies.set('token', token, '1h')
        this.state.token = token;
    }
    login(token: any, user: any) {
        this.state.user = user;
        this.setToken(token)
        this.state.isAuthenticated = true,
            axiosBlog.defaults.headers.common['Authorization'] = `Bearer ${token}`;
    }
    logout() {
        cookies.remove('token')
        this.state.isAuthenticated = false
        this.state.user = null;
        this.state.token = null;
        this.state.userDescription = null;
    }
}
export const userStore = new UserStore('USER_STORE');