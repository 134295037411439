import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router';
import Blog from '../views/Blog.vue';
// import Gallery from '../views/Gallery.vue';
const routes: Array<RouteRecordRaw> = [
  { path: '/', redirect: '/Blog' },
  {
    path: '/Blog',
    name: 'Blog',
    component: Blog,
    meta: {
      auth: true,
      title: 'Blog'
    }
  },
  {
    path: '/gallery',
    name: 'Gallery',
    component: () => import(/* webpackChunkName: "about" */ '../views/Gallery.vue'),
    meta: {
      auth: true,
      title: 'Galerie'
    },
  },
  {
    path: '/lightbox',
    name: 'Lightbox',
    component: () => import(/* webpackChunkName: "about" */ '../views/Lightbox.vue'),
    meta: {
      auth: true,
      title: 'Lightbox'
    },
  },

  {
    path: '/article/:id', name: 'Article', component: Blog, meta: {
      auth: true,
      title: 'Artikel'
    }
  },

  {
    path: '/category/:tag', name: 'tagPreview', component: Blog, meta: {
      auth: true,
      title: 'Kategorie'
    }
  },

  {
    path: '/about',
    name: 'About',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/About.vue'), meta: {
      auth: true,
      title: 'About'
    }
  }, {
    path: '/impressum',
    name: 'Impressum',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/Impressum.vue'), meta: {
      auth: true,
      title: 'Impressum'
    }
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});
router.replace('/Blog');
export default router;
