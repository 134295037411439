
import { defineComponent, ref, computed } from "vue";
import Password from "primevue/password";
import InputText from "primevue/inputtext";
import useAuthentication from '../composables/useAuthentication';
import { userStore } from '../store/user-store';

export default defineComponent({
  name: "LoginPanel",
  components: { Password, InputText },
  setup(props, { emit }) {
    const {isAuthenticated } = useAuthentication();
    const email = ref("");
    const password = ref("");
    const error = ref();

    const login = () => {
      userStore.getToken(email.value, password.value)
        .then(() => {
          emit("closePanel");
        })
        .catch((err: any) => {
          console.log(err);
          error.value = err;
        });
    };

    const logout = () => {
      userStore.logout();
      emit("closePanel");
    };
    return { email, password, login, error, logout, isAuthenticated };
  },
});
