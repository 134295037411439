
import { defineComponent, computed } from "vue";
import { axiosBlog } from "../api/axios";
import { CommentInterface } from "../interfaces/comment.interface";
import { useStore } from "vuex";
import { useConfirm } from "primevue/useconfirm";
import ConfirmPopup from "primevue/confirmpopup";
import useAuthentication from "../composables/useAuthentication";
export default defineComponent({
  props: {
    comment: { type: Object, required: true },
  },
  components: { ConfirmPopup },
  setup(props, { emit }) {
    const { isAuthenticated } = useAuthentication();

    const confirm = useConfirm();
    const confirmDeletion = (event: any) => {
      confirm.require({
        target: event.currentTarget,
        message: "Möchtest du den Artikel wirklich löschen?",
        icon: "pi pi-exclamation-triangle",
        accept: () => {
          deleteComment();
        },
        reject: () => {
          //do nothing
        },
      });
    };

    const deleteComment = async () => {
      const response = await axiosBlog.delete(
        "/api/comments/" + props.comment.id
      );
      emit("deleteComment", props.comment.id);
    };
    return {
      isAuthenticated,
      confirmDeletion,
    };
  },
});
