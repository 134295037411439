import { userStore } from '@/store/user-store';
import { computed } from 'vue';

export default function useAuthentication() {
  const isAuthenticated = computed(() => {
    return userStore.getState().isAuthenticated;
  });

  return {
    isAuthenticated,
  };
}
