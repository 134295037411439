import { PersistentStore } from "./main";
interface BlogStoreModel extends Object {
    isLoading: boolean,
}
// const BlogRepository = RepositoryFactory.get('blog');

class BlogStore extends PersistentStore<BlogStoreModel> {
    protected data(): BlogStoreModel {
        return {
            isLoading: false,
        };
    }
setIsLoading(value: boolean) {
    this.state.isLoading = value
}
}
export const blogStore = new BlogStore('BLOG_STORE');