
import { defineComponent, ref, computed, watch } from "vue";
import InputText from "primevue/inputtext";
import Editor from "primevue/editor";
import Chips from "primevue/chips";
import { ArticleInterface } from "../interfaces/article.interface";
import FileUpload from "./FileUpload.vue";
import InputSwitch from "primevue/inputswitch";
import { TagInterface } from "../interfaces/tag.interface";
import { useToast } from "primevue/usetoast";
import { articleStore } from '../store/article-store';

export default defineComponent({
  name: "BlogEntry",
  components: { InputText, Editor, Chips, InputSwitch, FileUpload },
  props: {
    articles: { type: Object, required: false },
    article: { type: Object, required: false },
  },
  setup(props, { emit }) {
    const title = ref("");
    const entryImg = ref("");
    const content = ref("");
    const tags = ref();
    const selectOptions = ref<string[]>([]);
    const publish = ref(true);
    const authorid = ref(1);
    const toast = useToast();
    const articleUpdated = ref(false);
    watch(props, () => {
      
      if (props.article) {
        title.value = props.article.title;
        entryImg.value = props.article.entryImg;
        content.value = props.article.content;

        if (props.article && props.article.tags) {
          props.article.tags.forEach((tag: TagInterface) => {
            selectOptions.value.push(tag.name);
          });
        }
        publish.value = props.article.published;
        authorid.value = props.article.authorid;
      }
    });
    const setTitleURL = (url: string) => {
      entryImg.value = url;
    };
    const processForm = () => {
      const form: ArticleInterface = {
        title: title.value,
        entryImg: entryImg.value,
        tags: Object.values(selectOptions.value),
        // tags: tag.value,
        date: new Date(),
        content: content.value,
        published: publish.value,
        authorid: authorid.value,
      };
      if (props.article) {
        form.date = props.article.date;
        form.modified = new Date();
        articleStore.updateArticle(props.article.id, form)
          .then((response: any) => {
            emit("articleResponse", response);
            articleUpdated.value = true;
            toast.add({
              severity: "success",
              summary: "Artikel aktualisiert",
              detail: "Der Artikel wurde erfolgreich aktualisiert",
              life: 3000,
            });
          })
          .catch((err: any) => {
            console.log(err);
            toast.add({
              severity: "error",
              summary: "Fehler",
              detail: "Der Artikel konnte nicht aktualisiert werden",
              life: 3000,
            });
          });
      } else {
        articleStore.createArticle(form)
          .then((response: any) => {
            emit("articleResponse", response);
            articleUpdated.value = true;
            toast.add({
              severity: "success",
              summary: "Artikel aktualisiert",
              detail: "Der Artikel wurde erfolgreich erstellt",
              life: 3000,
            });
          })
          .catch((err: any) => {
            console.log(err);
            toast.add({
              severity: "error",
              summary: "Fehler",
              detail: "Der Artikel konnte nicht aktualisiert werden",
              life: 3000,
            });
          });
      }
    };
    return {
      title,
      entryImg,
      content,
      selectOptions,
      processForm,
      setTitleURL,
      tags,
      publish,
      articleUpdated,
    };
  },
});
