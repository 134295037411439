import { resolveComponent as _resolveComponent, createVNode as _createVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, renderList as _renderList, Fragment as _Fragment, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2a03706c"),n=n(),_popScopeId(),n)
const _hoisted_1 = { key: 1 }
const _hoisted_2 = { key: 0 }
const _hoisted_3 = { key: 2 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_ArticleForm = _resolveComponent("ArticleForm")!
  const _component_Panel = _resolveComponent("Panel")!
  const _component_Article = _resolveComponent("Article")!

  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.isAuthenticated)
      ? (_openBlock(), _createBlock(_component_Panel, {
          key: 0,
          class: "create-panel",
          header: _ctx.article ? 'Artikel bearbeiten' : 'Neuer Eintrag',
          toggleable: true,
          collapsed: true
        }, {
          default: _withCtx(() => [
            _createVNode(_component_ArticleForm, {
              article: _ctx.article,
              onArticleResponse: _ctx.updateArticle
            }, null, 8, ["article", "onArticleResponse"])
          ]),
          _: 1
        }, 8, ["header"]))
      : _createCommentVNode("", true),
    (!_ctx.article)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.articles, (article, index) => {
            return (_openBlock(), _createElementBlock("div", {
              key: article.id
            }, [
              _createVNode(_component_Article, {
                article: article,
                preview: true,
                onOnUpdateArticle: _ctx.updateArticle
              }, null, 8, ["article", "onOnUpdateArticle"]),
              (index < _ctx.articles.length - 1)
                ? (_openBlock(), _createElementBlock("hr", _hoisted_2))
                : _createCommentVNode("", true)
            ]))
          }), 128))
        ]))
      : _createCommentVNode("", true),
    (_ctx.article)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createVNode(_component_Article, {
            article: _ctx.article,
            preview: false
          }, null, 8, ["article"])
        ]))
      : _createCommentVNode("", true)
  ]))
}