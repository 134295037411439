
import { defineComponent, ref, onMounted, watch } from "vue";
import InputSwitch from "primevue/inputswitch";
import useMediaPreference from "../composables/useMediaPreference";
export default defineComponent({
  name: "DarkMode",
  components: { InputSwitch },
  setup() {
    const { getDarkModePreference } = useMediaPreference();
    const darkMode = ref(false);
    const theme = ref("");
    const toggleTheme = () => {
      theme.value = theme.value == "darkMode" ? "" : "darkMode"; //toggles theme value
      document.documentElement.setAttribute("data-theme", theme.value); // sets the data-theme attribute
      localStorage.setItem("theme", theme.value); // stores theme value on local storage
    };
    watch(darkMode, () => {
      toggleTheme();
    });
    onMounted(() => {
      if (getDarkModePreference()) {
        darkMode.value = getDarkModePreference();
      }
      //if localStore exists, replace darkModePreference
      let localTheme = localStorage.getItem("theme"); //gets stored theme value if any
      if (localTheme) {
        darkMode.value = true;
        document.documentElement.setAttribute("data-theme", localTheme); // updates the data-theme attribute
      } else darkMode.value = false;
    });
    return { theme, darkMode, toggleTheme };
  },
});
