
import { defineComponent, watch, onMounted, ref, computed } from "vue";
import { axiosBlog } from "../api/axios";
import { CommentInterface } from "../interfaces/comment.interface";
import CommentForm from "./CommentForm.vue";
import Comment from "./Comment.vue";
import Panel from "primevue/panel";
import useAuthentication from '../composables/useAuthentication';

export default defineComponent({
  props: {
    article: { type: Object, required: true },
  },
  components: { CommentForm, Panel, Comment },
  setup(props) {
    const isAuthenticated = useAuthentication();
    const comments = ref<CommentInterface[]>();
    const isCollapsed = ref(true);
    const onUpdatePosted = (comment: CommentInterface) => {
      isCollapsed.value = true;
      comments.value
        ? comments.value.push(comment)
        : (comments.value = [comment]);
    };
    const onDeleteComment = async (id: number) => {
      comments.value = comments.value!.filter((comment: any) => {
        return comment.id !== id;
      });
      console.log("comments", comments.value);
    };
    onMounted(async () => {
      const response = await axiosBlog.get("/api/comments/" + props.article.id);
      comments.value = response.data.sort(function (a: any, b: any) {
        return a.date - b.date;
      });
    });

    watch(props.article, () => {
      console.log(props.article);
    });
    return {
      comments,
      onUpdatePosted,
      isCollapsed,
      isAuthenticated,
      onDeleteComment,
    };
  },
});
