import { createApp } from 'vue';
import App from './App.vue';
import router from './router';
// import store from './store';
import PrimeVue from 'primevue/config';
import Button from "primevue/button";
import ConfirmationService from 'primevue/confirmationservice';
import ToastService from 'primevue/toastservice';
import 'primevue/resources/themes/saga-blue/theme.css'; //theme
import 'primevue/resources/primevue.min.css'; //core css
import 'primeicons/primeicons.css';
import 'bootstrap/dist/css/bootstrap.css';

const app = createApp(App);
// app.use(store);
app.use(router);
app.use(ToastService);

app.use(ConfirmationService);
app.component('Button', Button)
app.use(PrimeVue);
app.mount('#app');
