
import { defineComponent, computed, onMounted, ref, watch } from "vue";
import { articleStore } from "../store/article-store";
import DarkMode from "./DarkMode.vue";

export default defineComponent({
  name: "Widget",
  components: { DarkMode },

  setup() {
    const getArticleTags = ref([]);
    const getLastArticles = ref([]);

    onMounted(() => {
      articleStore.getLastArticles(3).then((response: any) => {
        getLastArticles.value = response;
      });
      articleStore.getAllTags().then((response: any) => {
        getArticleTags.value = response;
      });
    });

    // watch(store.state.articles, (old, to) => {
    //   console.log(store.state.articles);

    //   console.log("old", old);
    //   console.log("to", to);
    // });
    return {
      getLastArticles,
      getArticleTags,
    };
  },
});
