
import { defineComponent, ref } from "vue";
import InputText from "primevue/inputtext";
import InputNumber from "primevue/inputnumber";
import Textarea from "primevue/textarea";
import { CommentInterface } from "../interfaces/comment.interface";
import { axiosBlog } from "../api/axios";
import { useToast } from "primevue/usetoast";
export default defineComponent({
  props: {
    article: { type: Object, required: true },
  },
  components: { InputText, InputNumber, Textarea },
  setup(props, { emit }) {
    const author = ref();
    const title = ref();
    const content = ref();
    const toast = useToast();
    const a = ref(Math.floor(Math.random() * 10));
    const b = ref(Math.floor(Math.random() * 10));
    const userSolution = ref();
    const solution = ref(a.value + b.value);
    const processCommentForm = async () => {
      if (userSolution.value === solution.value) {
        const form: CommentInterface = {
          author: author.value,
          title: title.value,
          content: content.value,
          date: new Date(),
          published: false,
        };
        const response = await axiosBlog.post(
          "/api/comments/" + props.article.id,
          form
        );
        emit("commentPosted", response.data);
        toast.add({
          severity: "success",
          summary: "Kommentar",
          detail: "Kommentar wurde erfolgreich abgeschickt",
          life: 3000,
        });
      } else {
        toast.add({
          severity: "error",
          summary: "Falsche Lösung",
          detail: "Bitte versuchen Sie es erneut",
          life: 3000,
        });
      }
    };
    return {
      author,
      title,
      content,
      processCommentForm,
      a,
      b,
      solution,
      userSolution,
    };
  },
});
