export default function useMediaPreference() {
  function getDarkModePreference() {
    const hasDarkPreference = window.matchMedia('(prefers-color-scheme: dark)').matches;
    return hasDarkPreference;
  }

  return {
    getDarkModePreference,
  };
}
