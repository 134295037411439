import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "row" }
const _hoisted_2 = { class: "col-lg-10" }
const _hoisted_3 = { class: "col-lg-2" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_Toast = _resolveComponent("Toast")!
  const _component_Header = _resolveComponent("Header")!
  const _component_Navigation = _resolveComponent("Navigation")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_Widget = _resolveComponent("Widget")!
  const _component_Footer = _resolveComponent("Footer")!

  return (_openBlock(), _createElementBlock("div", null, [
    _createVNode(_component_Toast),
    _createVNode(_component_Header),
    _createVNode(_component_Navigation),
    _createElementVNode("div", _hoisted_1, [
      _createElementVNode("div", _hoisted_2, [
        _createVNode(_component_router_view, { class: "content-area" })
      ]),
      _createElementVNode("div", _hoisted_3, [
        _createVNode(_component_Widget, { class: "widget-area" })
      ])
    ]),
    _createVNode(_component_Footer)
  ]))
}